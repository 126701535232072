<template>
  <div class="container">
    <div class="filter-container">
      <el-button
          size="mini"
          class="filter-item"
          style="margin-right:0"
          icon="el-icon-menu"
          type="success"
          @click="getALLList"
      >全部
      </el-button>
      <el-button
          size="mini"
          class="filter-item"
          type="primary"
          icon="el-icon-search"
          style="margin-left:6px"
          @click="dialogTSCXVisible = true"
      >查询
      </el-button>
      <el-button
          size="mini"
          class="filter-item"
          type="success"
          icon="el-icon-document"
          style="background:orange;border:none;margin-left:6px"
          @click="gotoBookcatalog"
      >图书编目
      </el-button>
      <el-button
          size="mini"
          class="filter-item"
          type="success"
          icon="el-icon-printer"
          style="background:#ff6a00;border:none;margin-left:6px"
          @click="btnPrint"
      >书标打印
      </el-button>
      <el-button
          size="mini"
          class="filter-item"
          type="success"
          icon="el-icon-printer"
          style="background:#ff9501;border:none;margin-left:6px"
          @click="btnBarCodePrint"
          v-show="true"
      >条码打印
      </el-button>
      <el-button
          v-if="!downloadLoading"
          size="mini"
          class="filter-item"
          type="info"
          icon="el-icon-download"
          style="margin-left:6px"
          @click="Download"
      >导出
      </el-button>
      <el-button
          v-if="downloadLoading"
          size="mini"
          :loading="downloadLoading"
          class="filter-item"
          type="info"
          style="margin-left:6px"
          icon="el-icon-download"
      >正在导出
      </el-button>

    </div>
    <el-table
        v-loading="listLoading"
        :data="list"
        border
        highlight-current-row
        style="width: 100%; overflow: auto"
    >
      <el-table-column
          show-overflow-tooltip
          label="批号"
          width="100"
          align="center"
      >
        <template v-slot="{ row }">
          <span>{{ row.StrLotNumber }}</span>
        </template>
      </el-table-column>
      <el-table-column
          show-overflow-tooltip
          label="正题名"
          align="center"
          width="140"
      >
        <template v-slot="{ row }">
          <span>{{ row.StrPositiveTitle }}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="图书条码"
          prop="id"
          align="center"
          width="100"
          show-overflow-tooltip
      >
        <template v-slot="{ row }">
          <span>{{ row.StrBookBarcode }}</span>
        </template>
      </el-table-column>
      <el-table-column
          show-overflow-tooltip
          label="ISBN"
          align="center"
          width="140"
      >
        <template v-slot="{ row }">
          <span>{{ row.StrISBN }}</span>
        </template>
      </el-table-column>
      <el-table-column
          show-overflow-tooltip
          label="分类号"
          width="80"
          align="center"
      >
        <template v-slot="{ row }">
          <span>{{ row.StrTypeName }}</span>
        </template>
      </el-table-column>
      <el-table-column
          show-overflow-tooltip
          label="出版社"
          width="160"
          align="center"
      >
        <template v-slot="{ row }">
          <span>{{ row.StrPublisher }}</span>
        </template>
      </el-table-column>
      <el-table-column
          show-overflow-tooltip
          label="书屋位置"
          align="center"
          width="160"
      >
        <template v-slot="{ row }">
          <span>{{ row.StrLibraryName }}</span>
        </template>
      </el-table-column>
      <el-table-column
          show-overflow-tooltip
          label="书架"
          align="center"
          width="160"
      >
        <template v-slot="{ row }">
          <span>{{ row.StrBookshelfName }}</span>
        </template>
      </el-table-column>
      <el-table-column
          show-overflow-tooltip
          label="现存量"
          align="center"
          width="80"
      >
        <template v-slot="{ row }">
          <span>{{ row.IntExistingStock }}</span>
        </template>
      </el-table-column>
      <el-table-column
          show-overflow-tooltip
          label="每套册数"
          align="center"
          width="80"
      >
        <template v-slot="{ row }">
          <span>{{ row.IntNumberCopies }}</span>
        </template>
      </el-table-column>
      <el-table-column
          show-overflow-tooltip
          label="每套价格"
          align="center"
          width="80"
      >
        <template v-slot="{ row }">
          <span>{{ row.FloatPricePer }}</span>
        </template>
      </el-table-column>
      <el-table-column
          show-overflow-tooltip
          label="第一责任者"
          width="100"
          align="center"
      >
        <template v-slot="{ row }">
          <span>{{ row.StrFirstResponsible }}</span>
        </template>
      </el-table-column>
      <el-table-column
          show-overflow-tooltip
          label="其他责任者"
          width="120"
          align="center"
      >
        <template v-slot="{ row }">
          <span>{{ row.StrOtherResponsible }}</span>
        </template>
      </el-table-column>
      <el-table-column
          show-overflow-tooltip
          label="译者"
          width="120"
          align="center">
        <template v-slot="{ row }">
          <span>{{ row.StrTranslator !== null ? row.StrTranslator : '' }}</span>
        </template>
      </el-table-column>
      <el-table-column
          show-overflow-tooltip
          label="版本"
          width="80"
          align="center"
      >
        <template v-slot="{ row }">
          <span>{{ row.StrVersion }}</span>
        </template>
      </el-table-column>
      <el-table-column
          show-overflow-tooltip
          label="分册号"
          width="80"
          align="center"
      >
        <template v-slot="{ row }">
          <span>{{ row.StrSectionNumber }}</span>
        </template>
      </el-table-column>
      <el-table-column
          show-overflow-tooltip
          label="分册名"
          width="100"
          align="center"
      >
        <template v-slot="{ row }">
          <span>{{ row.StrSectionName }}</span>
        </template>
      </el-table-column>
      <el-table-column
          show-overflow-tooltip
          label="副题目"
          width="160"
          align="center"
      >
        <template v-slot="{ row }">
          <span>{{ row.StrSubtitle }}</span>
        </template>
      </el-table-column>
      <el-table-column
          show-overflow-tooltip
          label="丛书题名"
          width="160"
          align="center"
      >
        <template v-slot="{ row }">
          <span>{{ row.StrSeriesTitle }}</span>
        </template>
      </el-table-column>

      <!--      <el-table-column-->
      <!--        show-overflow-tooltip-->
      <!--        label="分类名"-->
      <!--        width="160"-->
      <!--        align="center"-->
      <!--      >-->
      <!--        <template v-slot="{ row }">-->
      <!--          <span>{{ row.StrCategoryName }}</span>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <!--      <el-table-column-->
      <!--        show-overflow-tooltip-->
      <!--        label="附件"-->
      <!--        width="160"-->
      <!--        align="center"-->
      <!--      >-->
      <!--        <template v-slot="{ row }">-->
      <!--          <span>{{ row.StrAffix }}</span>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <!--      <el-table-column-->
      <!--        show-overflow-tooltip-->
      <!--        label="货币单位"-->
      <!--        width="160"-->
      <!--        align="center"-->
      <!--      >-->
      <!--        <template v-slot="{ row }">-->
      <!--          <span>{{ row.StrCurrencyUnit }}</span>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <el-table-column
          show-overflow-tooltip
          label="单价"
          width="100"
          align="center"
      >
        <template v-slot="{ row }">
          <span>{{ row.FloatPricePer }}</span>
        </template>
      </el-table-column>
      <el-table-column
          show-overflow-tooltip
          label="装订"
          width="80"
          align="center"
      >
        <template v-slot="{ row }">
          <span>{{ row.StrBinding }}</span>
        </template>
      </el-table-column>
      <el-table-column
          show-overflow-tooltip
          label="页卷数"
          width="80"
          align="center"
      >
        <template v-slot="{ row }">
          <span>{{ row.StrNumberPages }}</span>
        </template>
      </el-table-column>
      <el-table-column
          show-overflow-tooltip
          label="出版日期"
          width="160"
          align="center"
      >
        <template v-slot="{ row }">
          <span>{{ row.StrPublicationDate }}</span>
        </template>
      </el-table-column>

      <el-table-column
          show-overflow-tooltip
          label="出版地"
          width="160"
          align="center"
      >
        <template v-slot="{ row }">
          <span>{{ row.StrPublisherPlace }}</span>
        </template>
      </el-table-column>
      <el-table-column
          show-overflow-tooltip
          label="种次号"
          width="80"
          align="center"
      >
        <template v-slot="{ row }">
          <span>{{ row.StrBookNumber }}</span>
        </template>
      </el-table-column>
      <el-table-column
          show-overflow-tooltip
          label="录入日期"
          width="120"
          align="center"
      >
        <template v-slot="{ row }">
          <span>{{ new Date(row.DTCreatedDate).toLocaleDateString() }}</span>
        </template>
      </el-table-column>
      <el-table-column
          show-overflow-tooltip
          label="索书号"
          width="120"
          align="center"
      >
        <template v-slot="{ row }">
          <span v-if="row.StrCallNumber === ''">{{ row.StrTypeName }}/{{ row.StrBookNumber }}</span>
          <span v-else>{{ row.StrCallNumber }}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="操作"
          align="center"
          width="160"
          class-name="small-padding fixed-width"
          fixed="right">
        <template v-slot="{ row }">
          <el-button type="primary" size="mini" @click="handleUpdate(row)"
          >编辑
          </el-button
          >
          <el-button
              v-if="row.status != 'deleted'"
              size="mini"
              type="danger"
              @click="BookDelete(row)"
          >删除
          </el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 20px; text-align: right">
      <el-pagination
          background
          :total="total"
          :current-page="listQuery.Page"
          :page-size="listQuery.Perpage"
          :page-sizes="[5, 10, 20, 30, 40, 50, 100]"
          layout="prev,pager,next,jumper,sizes,total"
          @size-change="pageSizeChange"
          @current-change="currPageChange"
      />
    </div>
    <el-dialog
        title="图书修改"
        v-model="dialogFormVisible"
        top="7vh"
        :close-on-click-modal="false"
    >
      <el-tabs v-model="activeName">
        <el-tab-pane label="图书基本信息" name="first">
          <el-form
              ref="dataTsForm"
              label-width="100px"
              size="small"
              :model="formModel"
              :rules="rules"
          >
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="封面">
                  <el-upload
                      class="avatar-uploader"
                      :class="{ disabled: uploadDisabled }"
                      :headers="picheaders"
                      :data="uploadtype"
                      :action="uploadUrl"
                      list-type="picture-card"
                      :before-upload="beforeUpload"
                      :on-preview="handlePictureCardPreview"
                      :on-change="handleChange"
                      :on-remove="handleRemove"
                      :on-success="handleSuccess"
                      accept=".jpg,.jpeg,.png"
                      :file-list="fileList">
                    <i class="el-icon-plus"/>
                  </el-upload>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="正题名" prop="StrPositiveTitle">
                  <el-input v-model="formModel.StrPositiveTitle"/>
                </el-form-item>
                <el-form-item label="附件" prop="title">
                  <el-input v-model="formModel.StrAffix"/>
                </el-form-item>
                <el-form-item label="第一责任者" prop="StrFirstResponsible">
                  <el-input v-model="formModel.StrFirstResponsible"/>
                </el-form-item>
                <el-form-item label="其他责任者" prop="title">
                  <el-input v-model="formModel.StrOtherResponsible"/>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="title" label="副题名">
                  <el-input v-model="formModel.StrSubtitle"/>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="title" label="丛书题名">
                  <el-input v-model="formModel.StrSeriesTitle"/>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="title" label="版本">
                  <el-input v-model="formModel.StrVersion"/>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="title" label="分册号">
                  <el-input v-model="formModel.StrSectionNumber"/>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="title" label="装订">
                  <el-input v-model="formModel.StrBinding"/>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="title" label="主题词">
                  <el-input v-model="formModel.StrKeywords"/>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="title" label="出版社">
                  <el-input v-model="formModel.StrPublisher"/>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="title" label="出版日期">
                  <el-input v-model="formModel.StrPublicationDate"/>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="title" label="卷页数">
                  <el-input v-model="formModel.StrNumberPages"/>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="title" label="出版地">
                  <el-input v-model="formModel.StrPublisherPlace"/>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="StrISBN" label="ISBN">
                  <el-input v-model="formModel.StrISBN"/>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="title" label="每套册数">
                  <el-input v-model="formModel.IntNumberCopies"/>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="StrTypeName" label="分类号">
                  <el-input v-model="formModel.StrTypeName" @blur="check"/>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="title" label="预定册数">
                  <el-input v-model="formModel.IntOrderNumber"/>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="title" label="种次号">
                  <el-input v-model="formModel.StrBookNumber" readonly="true"/>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="title" label="卷册">
                  <el-input v-model="formModel.StrSectionName"/>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="FloatPricePer" label="分册价格">
                  <el-input v-model="formModel.FloatPricePer"/>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="StrBookBarcode" label="条形码">
                  <el-input v-model="formModel.StrBookBarcode"/>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="书本简介">
                  <el-input v-model="formModel.StrSummary"/>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="书屋信息" name="second">
          <el-form label-width="150px" size="small" :model="formModel">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item prop="title" label="书屋位置">
                  <el-select
                      v-model="formModel.StrLibraryID"
                      class="filter-item"
                      placeholder="请选择"
                      @change="selectSW"
                      :disabled="true"
                  >
                    <el-option
                        v-for="item in swList"
                        :key="item.StrID"
                        :label="item.StrName"
                        :value="item.StrID"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="title" label="书架">
                  <el-select
                      v-model="formModel.StrBookshelfID"
                      class="filter-item"
                      placeholder="请选择"
                      :disabled="true"
                  >
                    <el-option
                        v-for="item in bookshelfs"
                        :key="item.StrID"
                        :label="item.StrName"
                        :value="item.StrID"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="录入日期" prop="title">
                  <el-input v-model="formModel.DTCreatedDate" readonly="true"/>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="借阅次数" prop="title">
                  <el-input
                      v-model="formModel.IntBorrowedCount"
                      readonly="true"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="是否出借" prop="title">
                  <el-input v-model="formModel.IsBorrowed" readonly="true"/>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="当前库存" prop="title">
                  <el-input
                      v-model="formModel.IntExistingStock"
                      readonly="true"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="当前借阅者" prop="title">
                  <el-input
                      v-model="formModel.currentReaerName"
                      readonly="true"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="读者卡号" prop="title">
                  <el-input
                      v-model="formModel.currentReaerNumber"
                      readonly="true"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="此类丢失" prop="title">
                  <el-input v-model="formModel.IsLost" readonly="true"/>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="此类损毁" prop="title">
                  <el-input v-model="formModel.IsBroken" readonly="true"/>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
      </el-tabs>
      <div style="float: right; border: 10px">
        <el-button size="mini" @click="dialogFormVisible = false"
        >取消
        </el-button
        >
        <el-button type="primary" size="mini" @click="updateData"
        >确定
        </el-button
        >
      </div>
      <p style="color: #ffffff">三阅</p>
    </el-dialog>
    <!-- 图片预览 -->
    <el-dialog title="图片预览" width="40%" v-model="showPictureCardPreview">
      <div style="text-align: center">
        <img :src="showImg"/>
      </div>
    </el-dialog>
    <el-dialog
        title="图书查询"
        width="40%"
        v-model="dialogTSCXVisible"
        :close-on-click-modal="false"
    >
      <el-form label-width="150px" size="small" :model="search">
        <el-row :gutter="20">
          <el-col>
            <el-form-item label="ISBN" prop="title">
              <el-input v-model="search.StrISBN"/>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="作者" prop="title">
              <el-input v-model="search.StrFirstResponsible"/>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="正题名" prop="title">
              <el-input v-model="search.StrPositiveTitle"/>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="图书条码" prop="title">
              <div style="display: flex">
                <el-input v-model="search.StrBookBarcode" size="mini"/>
              </div>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="图书分类" prop="title">
              <el-input v-model="search.StrTypeName"/>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="编目日期" prop="title">
              <el-date-picker
                  v-model="search.DTStartCreatedDate"
                  type="date"
                  placeholder="选择日期"
                  value-format="YYYY-MM-DD"
                  :disabled-date="disabledDate"
                  @change = "choose"

              />
              至
              <el-date-picker
                  v-model="search.DTEndCreatedDate"
                  type="date"
                  placeholder="选择日期"
                  value-format="YYYY-MM-DD"
                  :disabled-date="disabledDate"
                  @change = "choose"
              />
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="书屋位置" prop="title">
              <el-select v-model="search.StrLibraryID" placeholder="请选择">
                <el-option
                    v-for="item in swList"
                    :key="item.StrID"
                    :label="item.StrName"
                    :value="item.StrID"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span class="dialog-footer" style="float: right">
        <el-button size="mini" @click="dialogTSCXVisible = false"
        >取 消</el-button
        >
        <el-button size="mini" type="primary" @click="handleFilter"
        >确 定</el-button
        >
      </span>
      <p style="color: #ffffff">三阅</p>
    </el-dialog>
    <!-- 书标条码打印 -->
    <el-dialog title="选择打印" v-model="dialogVisible" width="410px" top="22vh">
      <el-form ref="formLableCode" :model="formLableCode" :rules="ruleCode" label-width="100px">
        <el-form-item label="打印方式：">
          <el-select v-model="formLableCode.type" size="small" placeholder="请选择打印类型">
            <el-option v-for="(item, index) in typeList" :key="index" :label="item.label" :value="item.id"/>
          </el-select>
        </el-form-item>
        <div v-if="formLableCode.type === 1">
          <el-form-item label="批号编目：" prop="StrLotNumber">
            <el-select v-model="formLableCode.StrLotNumber" size="small" placeholder="请选择批号" @change="bookDataList">
              <el-option v-for="(item, index) in batchList" :key="index" :label="item.StrLotNumber"
                         :value="item.StrLotNumber"/>
            </el-select>
          </el-form-item>
          <div v-if="formLableCode.StrLotNumber">
            <p style="margin-left: 100px; color: red; font-size: 14px">
              当前共
              <span style="color: black">{{ total }}</span>
              条数据，请选择打印区间
            </p>
          </div>
          <el-form-item v-if="formLableCode.StrLotNumber && total !== 0" label="批号条数：" required>
            <el-col :span="11">
              <el-form-item prop="Start">
                <el-input v-model="formLableCode.Start" type="number" min="1" :max="batchTotal" placeholder="开始条数"
                          size="small"/>
              </el-form-item>
            </el-col>
            <el-col :span="2" style="text-align: center">
              <span>&nbsp;至&nbsp;</span>
            </el-col>
            <el-col :span="11">
              <el-form-item prop="End">
                <el-input v-model="formLableCode.End" type="number" :min="parseInt(formLableCode.Start)"
                          :max="batchTotal" placeholder="结束条数" size="small"/>
              </el-form-item>
            </el-col>
          </el-form-item>
        </div>
        <div v-if="formLableCode.type === 2">
          <el-form-item label="批号编目：" prop="StrLotNumber">
            <el-select v-model="formLableCode.StrLotNumber" size="small" placeholder="请选择批号" @change="bookDataList">
              <el-option v-for="(item, index) in batchList" :key="index" :label="item.StrLotNumber"
                         :value="item.StrLotNumber"/>
            </el-select>
          </el-form-item>
          <div v-if="formLableCode.StrLotNumber">
            <p style="margin-left: 100px; color: red; font-size: 14px">请填写打印条码区间</p>
          </div>
          <el-form-item v-if="formLableCode.StrLotNumber && total !== 0" label="起始条码：" required>
            <el-col :span="11">
              <el-form-item prop="StrStartBookBarcode">
                <el-input v-model="formLableCode.StrStartBookBarcode" placeholder="开始条码" size="small"/>
              </el-form-item>
            </el-col>
            <el-col :span="2" style="text-align: center">
              <span>&nbsp;至&nbsp;</span>
            </el-col>
            <el-col :span="11">
              <el-form-item prop="StrEndBookBarcode">
                <el-input v-model="formLableCode.StrEndBookBarcode" placeholder="结束条码" size="small"/>
              </el-form-item>
            </el-col>
          </el-form-item>
        </div>
      </el-form>
      <span class="dialog-footer" style="float: right; border: 10px">
        <el-button size="small" @click="closeLableCode">取 消</el-button>
        <el-button size="small" type="primary" @click="labelCodeSure('formLableCode')">确 定</el-button>
      </span>
      <p style="color: #ffffff">三阅</p>
    </el-dialog>

    <!-- 书标条码打印 -->
    <el-dialog title="设置" v-model="dialogVisibleSet" width="560px" top="22vh">
      <el-form
          ref="formStyleSet"
          :model="formStyleSet"
          :rules="ruleCode"
          label-width="100px"
      >
        <el-form-item label="打印类型：">
          <el-select
              v-model="formStyleSet.type"
              size="small"
              placeholder="请选择打印类型"
              @change="typeChange"
          >
            <el-option
                v-for="(item, index) in typeList"
                :key="index"
                :label="item.label"
                :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item prop="Start">
          <el-input
              v-model="formStyleSet.Start"
              type="number"
              min="1"
              :max="batchTotal"
              placeholder="开始条数"
              size="small"
          />
        </el-form-item>
      </el-form>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogVisibleSet = false"
        >取 消</el-button
        >
        <el-button size="small" type="primary" @click="styleSet('formStyleSet')"
        >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 书标条码打印样式设置 -->
    <el-dialog
        title="书标打印参数设置"
        v-model="dialogVisiblePrint"
        width="700px"
        top="22vh"
    >
      <div>打印布局设置（单位：mm）</div>
      <ul class="print-set">
        <li class="print-set-item">
          <div class="print-set-title">纸张</div>
          <div class="print-set-option">宽度</div>
          <el-input
              v-model="printSet.width"
              type="number"
              :min="1"
              size="mini"
              style="width: 70px"
          />
          <div class="print-set-option">高度</div>
          <el-input
              v-model="printSet.height"
              type="number"
              :min="1"
              size="mini"
              style="width: 70px"/>
        </li>
        <li class="print-set-item">
          <div class="print-set-title">书标排版</div>
          <div class="print-set-option">行数</div>
          <el-input
              v-model="printSet.row"
              type="number"
              :min="1"
              size="mini"
              style="width: 71px"
          />
          <div class="print-set-option">列数</div>
          <el-input
              v-model="printSet.col"
              type="number"
              :min="1"
              size="mini"
              style="width: 70px"
          />

        </li>
        <li class="print-set-item">
          <div class="print-set-title">书标区域</div>
          <div class="print-set-option">上边距</div>
          <el-input
              v-model="printSet.paddingTop"
              type="number"
              :min="0"
              size="mini"
              style="width: 70px"
          />
          <div class="print-set-option">下边距</div>
          <el-input
              v-model="printSet.paddingBottom"
              type="number"
              :min="0"
              size="mini"
              style="width: 70px"
          />
          <div class="print-set-option">左边距</div>
          <el-input
              v-model="printSet.paddingLeft"
              type="number"
              :min="0"
              size="mini"
              style="width: 70px"
          />
          <div class="print-set-option">右边距</div>
          <el-input
              v-model="printSet.paddingRight"
              type="number"
              :min="0"
              size="mini"
              style="width: 70px"
          />
        </li>
        <li class="print-set-item">
          <div class="print-set-title">内容排列</div>
          <div class="print-set-option">垂直</div>
          <el-select
              v-model="printSet.verticalAlign"
              size="mini"
              style="width: 120px"
          >
            <el-option label="顶部对齐" value="flex-start"/>
            <el-option label="居中对齐" value="center"/>
            <el-option label="底部对齐" value="flex-end"/>
          </el-select>
          <div class="print-set-option">水平</div>
          <el-select
              v-model="printSet.horizontalAlign"
              size="mini"
              style="width: 120px"
          >
            <el-option label="水平居中" value="center"/>
            <el-option label="左对齐" value="left"/>
            <el-option label="右对齐" value="right"/>
          </el-select>
        </li>
        <li class="print-set-item">
          <div class="print-set-title">条码位置</div>
          <el-radio v-model="printSet.overturn" label="1" style="margin-left: 40px">上</el-radio>
          <el-radio v-model="printSet.overturn" label="2">下</el-radio>
        </li>
        <li class="print-set-item">
          <div class="print-set-title">其他</div>
          <div class="print-set-option">字号</div>
          <el-select
              v-model="printSet.fontSize"
              size="mini"
              style="width: 120px"
          >
            <el-option
                v-for="item in [
                10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
                26,
              ]"
                :key="item"
                :label="item"
                :value="item"
            />
          </el-select>
          <div class="print-set-option">行高</div>
          <el-select
              v-model="printSet.lineHeight"
              size="mini"
              style="width: 120px">
            <el-option
                v-for="item in [16, 18, 20, 22, 24, 26, 28, 30]"
                :key="item"
                :label="item"
                :value="item"
            />
          </el-select>
        </li>

      </ul>
      <div
          v-show="preview"
          class="print-box"
          :style="{ transform: `scale(${scale / 100})` }"
      >
        <div ref="print">
          <div
              :style="{
              width: `${printSet.width}mm`,
              paddingTop: `${printSet.paddingTop}mm`,
              paddingBottom: `${printSet.paddingBottom}mm`,
              paddingLeft: `${printSet.paddingLeft}mm`,
              paddingRight: `${printSet.paddingRight}mm`,
            }"
          >
            <div
                v-for="(item, index) in printListFilter"
                :key="index"
                style="display: inline-block"
                :style="{
                textAlign: `center`,
                width: `${(printSet.width-printSet.paddingLeft-printSet.paddingRight)/printSet.col-0.05}mm`,
                height: `${(printSet.height-printSet.paddingTop-printSet.paddingBottom)/printSet.row-0.05}mm`,
                textAlign: printSet.horizontalAlign,
                lineHeight: `${printSet.lineHeight}px`,
                fontSize: `${printSet.fontSize}px`,
              }"
            >
              <div
                  style="display: flex; flex-direction: column; height: 100%"
                  :style="{ justifyContent: printSet.verticalAlign }">
                <template v-if="printSet.overturn==='1'">
                  <div>{{ item.StrBookBarcode }}</div>
                  <div>{{ item.StrTypeName }}/{{ item.StrBookNumber }}</div>
                </template>
                <template v-else>
                  <div>{{ item.StrTypeName }}/{{ item.StrBookNumber }}</div>
                  <div>{{ item.StrBookBarcode }}</div>
                </template>
              </div>
            </div>
            <!--            <div-->
            <!--              v-for="index in placeTotal"-->
            <!--              v-show="place"-->
            <!--              :key="index"-->
            <!--              style="display: inline-block"-->
            <!--              :style="{-->
            <!--                width: `${100 / printSet.col}%`,-->
            <!--                height: `${printSet.height}px`,-->
            <!--              }"-->
            <!--            />-->
          </div>
        </div>
      </div>
      <p style="color: #ffffff">三阅</p>
      <div
          class="dialog-footer"
          style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
        <div class="dialog-footer" style="float: right; border: 10px">
          <el-button size="small" @click="dialogVisiblePrint = false">取 消</el-button>
          <el-button size="small" type="primary" plain @click="resetPrint()">还 原</el-button>
          <span style="margin-left:12px; color:black;">本次共选择{{ printListSize }}条数据</span>
        </div>
        <div style="display: flex; align-items: center">
          <div
              v-show="preview"
              style="display: flex; align-items: center; margin-right: 10px"
          >
            <span style="font-size: 12px">预览缩放：</span>
            <el-slider
                v-model="scale"
                :format-tooltip="(val) => val / 100"
                style="width: 120px"
            />
          </div>
          <el-button
              size="small"
              type="success"
              plain
              @click="preview = !preview"
          >预 览
          </el-button
          >
          <el-button size="small" type="primary" @click="printTrue"
          >打 印
          </el-button
          >
        </div>
      </div>
    </el-dialog>


    <!-- 条码打印，最后一个按钮 -->
    <el-dialog title="选择打印" v-model="dialogBarCodeVisible" width="410px" top="22vh">
      <el-form ref="formBarCode" :model="formBarCode" :rules="ruleBarCode" label-width="100px">
        <el-form-item label="馆名称：" prop="lirName">
          <el-col :span="23">
            <el-input v-model="formBarCode.lirName" placeholder="请输入馆名称" size="small"/>
          </el-col>
        </el-form-item>
        <el-form-item label="条码长度：" prop="barCodeLen">
          <el-col :span="23">
            <el-input v-model="formBarCode.barCodeLen" type="number" min="1" placeholder="请输入条码长度" size="small"/>
          </el-col>
        </el-form-item>
        <el-form-item label="开始条码：" prop="beginBarCode">
          <el-col :span="23">
            <el-input v-model="formBarCode.beginBarCode" placeholder="请输入开始条码" size="small"/>
          </el-col>
        </el-form-item>
        <el-form-item label="条码个数：" prop="barCodeNum">
          <el-col :span="23">
            <el-input v-model="formBarCode.barCodeNum" type="number" min="1" placeholder="请输入条码个数" size="small"/>
          </el-col>
        </el-form-item>
      </el-form>
      <span class="dialog-footer" style="float: right; border: 10px">
        <el-button size="small" @click="closeLableCode">取 消</el-button>
        <el-button size="small" type="primary" @click="barCodeSure">确 定</el-button>
      </span>
      <p style="color: #ffffff">三阅</p>
    </el-dialog>

    <el-dialog
        title="条码打印参数设置"
        v-model="dialogVisiblePrintCodeBar"
        width="700px"
        top="22vh">
      <div>打印布局设置</div>
      <ul class="print-set">
        <li class="print-set-item">
          <div class="print-set-title">纸张</div>
          <div class="print-set-option">宽度</div>
          <el-input
              v-model="printSetBarCode.width"
              type="number"
              :min="1"
              size="mini"
              style="width: 70px"
          />
          <!--          <div class="print-set-option">高度</div>-->
          <!--          <el-input-->
          <!--              v-model="printSet.height"-->
          <!--              type="number"-->
          <!--              :min="1"-->
          <!--              size="mini"-->
          <!--              style="width: 70px"-->
          <!--          />-->
        </li>
        <li class="print-set-item">
          <div class="print-set-title">条码</div>
          <div class="print-set-option">高度</div>
          <el-input
              v-model="printSetBarCode.barCodeHeight"
              type="number"
              :min="1"
              size="mini"
              style="width: 70px"
          />
          <div class="print-set-option">列数</div>
          <el-input
              v-model="printSetBarCode.columns"
              type="number"
              :min="1"
              size="mini"
              style="width: 70px"
          />
          <div class="print-set-option">水平间距</div>
          <el-input
              v-model="printSetBarCode.marginLeft"
              type="number"
              :min="0"
              size="mini"
              style="width: 70px"
          />
          <div class="print-set-option">垂直间距</div>
          <el-input
              v-model="printSetBarCode.marginTop"
              type="number"
              :min="0"
              size="mini"
              style="width: 70px"
          />
        </li>
<!--        <li class="print-set-item">-->
<!--          <div class="print-set-title">条码区域</div>-->
<!--          <div class="print-set-option">上边距</div>-->
<!--          <el-input-->
<!--              v-model="printSetBarCode.paddingTop"-->
<!--              type="number"-->
<!--              :min="0"-->
<!--              size="mini"-->
<!--              style="width: 70px"-->
<!--          />-->
<!--          <div class="print-set-option">下边距</div>-->
<!--          <el-input-->
<!--              v-model="printSetBarCode.paddingBottom"-->
<!--              type="number"-->
<!--              :min="0"-->
<!--              size="mini"-->
<!--              style="width: 70px"-->
<!--          />-->
<!--          <div class="print-set-option">左边距</div>-->
<!--          <el-input-->
<!--              v-model="printSetBarCode.paddingLeft"-->
<!--              type="number"-->
<!--              :min="0"-->
<!--              size="mini"-->
<!--              style="width: 70px"-->
<!--          />-->
<!--          <div class="print-set-option">右边距</div>-->
<!--          <el-input-->
<!--              v-model="printSetBarCode.paddingRight"-->
<!--              type="number"-->
<!--              :min="0"-->
<!--              size="mini"-->
<!--              style="width: 70px"-->
<!--          />-->
<!--        </li>-->
        <li class="print-set-item">
          <div class="print-set-title">内容排列</div>
          <div class="print-set-option">垂直</div>
          <el-select
              v-model="printSetBarCode.verticalAlign"
              size="mini"
              style="width: 120px"
          >
            <el-option label="顶部对齐" value="flex-start"/>
            <el-option label="居中对齐" value="center"/>
            <el-option label="底部对齐" value="flex-end"/>
          </el-select>
          <div class="print-set-option">水平</div>
          <el-select
              v-model="printSetBarCode.horizontalAlign"
              size="mini"
              style="width: 120px"
          >
            <el-option label="水平居中" value="center"/>
            <el-option label="左对齐" value="left"/>
            <el-option label="右对齐" value="right"/>
          </el-select>
        </li>
        <li class="print-set-item">
          <div class="print-set-title">其他</div>
          <div class="print-set-option">字号</div>
          <el-select
              v-model="printSetBarCode.fontSize"
              size="mini"
              style="width: 120px"
          >
            <el-option
                v-for="item in [
                10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
                26,
              ]"
                :key="item"
                :label="item"
                :value="item"
            />
          </el-select>
          <div class="print-set-option">行高</div>
          <el-select
              v-model="printSetBarCode.lineHeight"
              size="mini"
              style="width: 120px"
          >
            <el-option
                v-for="item in [16, 18, 20, 22, 24, 26, 28, 30]"
                :key="item"
                :label="item"
                :value="item"
            />
          </el-select>
        </li>
      </ul>
      <div
          v-show="previewBarCode"
          class="print-box"
          :style="{ transform: `scale(${scaleBarCode / 100})` }"
      >
        <div ref="printBarCode">
          <div
              :style="{
              textAlign: `center`,
              width: `${printSetBarCode.width-1-printSetBarCode.paddingLeft-printSetBarCode.paddingRight}mm`,
              paddingTop: `${printSetBarCode.paddingTop}mm`,
              paddingBottom: `${printSetBarCode.paddingBottom}mm`,
              paddingLeft: `${printSetBarCode.paddingLeft}mm`,
              paddingRight: `${printSetBarCode.paddingRight}mm`,
            }"
          >
            <div
                v-for="(item, index) in printListFilterBarCode"
                :key="index"
                style="display: inline-block"
                :style="{
                width: `${(printSetBarCode.width-printSetBarCode.paddingLeft-printSetBarCode.paddingRight) / printSetBarCode.columns-printSetBarCode.marginLeft-0.05}mm`,
                height: `${printSetBarCode.barCodeHeight}mm`,
                marginLeft: `${(index===0||index%printSetBarCode.columns===0)?0:printSetBarCode.marginLeft}mm`,
                marginTop: `${printSetBarCode.marginTop}mm`,
                textAlign: printSetBarCode.horizontalAlign,
                lineHeight: `${printSetBarCode.lineHeight}px`,
                fontSize: `${printSetBarCode.fontSize}px`,
              }">
              <div
                  style="display: flex; flex-direction: column; height: 100%"
                  :style="{ justifyContent: printSetBarCode.verticalAlign }">
                <div>{{ item.lirName }}</div>
                <img :src="item.img">
                <div>{{ item.barCode }}</div>
              </div>
            </div>
            <!--            <div-->
            <!--                v-for="index in placeTotal"-->
            <!--                v-show="place"-->
            <!--                :key="index"-->
            <!--                style="display: inline-block"-->
            <!--                :style="{-->
            <!--                width: `${100 / printSet.col}%`,-->
            <!--                height: `${100 / printSet.row}%`,-->
            <!--              }"-->
            <!--            />-->

          </div>
          <!--          <div-->
          <!--              :style="{-->
          <!--              textAlign: `center`,-->
          <!--              width: `${printSetBarCode.width-printSetBarCode.paddingLeft-printSetBarCode.paddingRight}mm`,-->
          <!--              paddingTop: `${printSetBarCode.paddingTop}mm`,-->
          <!--              paddingBottom: `${printSetBarCode.paddingBottom}mm`,-->
          <!--              paddingLeft: `${printSetBarCode.paddingLeft}mm`,-->
          <!--              paddingRight: `${printSetBarCode.paddingRight}mm`,-->
          <!--            }"-->
          <!--          >-->
          <!--            <div-->
          <!--                v-for="index in placeTotal"-->
          <!--                v-show="place"-->
          <!--                :key="index"-->
          <!--                style="display: inline-block"-->
          <!--                :style="{-->
          <!--                width: `${100 / printSet.col}%`,-->
          <!--                height: `${100 / printSet.row}%`,-->
          <!--              }"-->
          <!--            />-->
          <!--          </div>-->
        </div>
      </div>
      <p style="color: #ffffff">三阅</p>
      <div
          class="dialog-footer"
          style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
        <div class="dialog-footer" style="float: right; border: 10px">
          <el-button size="small" @click="dialogVisiblePrintCodeBar = false"
          >取 消
          </el-button
          >
          <el-button size="small" type="primary" plain @click="resetPrintBarCode()"
          >还 原
          </el-button
          >
          <span style="margin-left:12px; color:black;">本次共选择{{ printListSizeBarCode }}条数据</span>
        </div>
        <div style="display: flex; align-items: center">
          <div
              v-show="previewBarCode"
              style="display: flex; align-items: center; margin-right: 10px"
          >
            <span style="font-size: 12px">预览缩放：</span>
            <el-slider
                v-model="scaleBarCode"
                :format-tooltip="(val) => val / 100"
                style="width: 120px"
            />
          </div>
          <el-button
              size="small"
              type="success"
              plain
              @click="previewBarCode = !previewBarCode">预 览
          </el-button>
          <el-button size="small" type="primary" @click="printTrueBarCode"
          >打 印
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import global from "@/utils/global";
import md5 from "js-md5";
import Compressor from "compressorjs";
import {ElMessage} from "element-plus";

export default {
  name: "bookfile",

  data() {

    return {

      StrLotNumber: "", // 批号
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        Page: 1,
        Perpage: 10,
        intNotOn: "1",
        Sort: "-1",
        OrderBy: "DTCreatedDate"
      },
      // disable: true, // 书架
      dialogFormVisible: false,
      dialogStatus: "",
      formSJModel: {},
      //表单验证
      ruleCode: {
        StrLotNumber: [
          {required: true, message: "请选择批号", trigger: "blur"},
        ],
        Start: [{required: true, message: "请填写开始条数", trigger: "blur"}],
        End: [{required: true, message: "请填写结束条码", trigger: "blur"}],
        StrStartBookBarcode: [
          {required: true, message: "请填写开始条码", trigger: "blur"},
        ],
        StrEndBookBarcode: [
          {required: true, message: "请填写结束条码", trigger: "blur"},
        ],
      },

      rules: {
        StrLibraryID: [
          {required: true, message: "请选择书屋", trigger: "chanage"},
        ],
        StrBookshelfID: [
          {required: true, message: "请选择书架", trigger: "chanage"},
        ],
        StrPositiveTitle: [
          {required: true, message: "请输入正题名", trigger: "blur"},
        ],
        StrFirstResponsible: [
          {required: true, message: "请输入第一责任者", trigger: "blur"},
        ],
        StrTypeName: [
          {required: true, message: "请输入分类号", trigger: "blur"},
        ],
        StrISBN: [{required: true, message: "请输入ISBN", trigger: "blur"}],
        StrBookBarcode: [
          {required: true, message: "请输入条形码", trigger: "blur"},
        ],
        FloatPricePer: [
          {required: true, message: "请输入每册价格", trigger: "blur"},
        ],
      },
      downloadLoading: false,
      xsSize: 24,
      smSize: 24,
      mdSize: 12,
      lgSize: 12,
      xlSize: 12,
      dialogTSCXVisible: false,
      dialogBGSWVisible: false,
      formModel: {
        StrCover: "",
      },
      picheaders: {},
      uploadtype: {
        type: 'image/bookcover'
      },
      myurl: this.localpath + "books/",
      TSCXFormModel: {},
      formBGSWModel: {},
      swList: [],
      bookshelfs: [], // 根据书屋选择书架的
      sjAllList: [], // 所有书架
      activeName: "first",
      uploadUrl: "",
      fileList: [],
      Url: "",
      strBookshelfId: "",
      // 书标
      dialogVisibleSet: false,
      dialogVisible: false,
      formLableCode: {
        type: 1,
      },

      typeList: [
        {
          id: 1,
          label: "按编目顺序打印",
        },
        {
          id: 2,
          label: "按条码区间打印",
        },
      ],
      returnList: [
        {
          id: 0,
          label: "返回条数",
        },
        {
          id: 0,
          label: "返回数据",
        },
      ],
      batchList: [],
      batchTotal: null,
      // 设置
      formStyleSet: {},
      // 要打印的列表
      printList: [],
      // 垂直
      printSet: {
        // 纸张宽度
        width: 210,
        //书标高度
        height: 297,
        // 书标区域
        paddingLeft: 10,
        paddingTop: 5,
        paddingRight: 10,
        paddingBottom: 0,
        // 书标排版
        row: 10,
        col: 5,
        // 内容排列
        // 垂直
        verticalAlign: "center",
        // 水平
        horizontalAlign: "center",
        // 行高
        lineHeight: 18,
        // 字体大小
        fontSize: 14,
        overturn: '2'
      },
      dialogVisiblePrint: false,
      // 预览
      preview: false,
      // 缩放
      scale: 100,
      // 图书编目传来的批号
      receiveStrLotNumber: "",
      //图片预览
      showPictureCardPreview: false,
      showImg: "",
      printListSize: 0,

      //点击查询时对应的json
      search: {},

      //条码打印的data，最后一个按钮
      printListSizeBarCode: 0,
      dialogBarCodeVisible: false,
      dialogVisiblePrintCodeBar: false,
      // 预览
      previewBarCode: false,
      // 缩放
      scaleBarCode: 100,
      //表单验证
      ruleBarCode: {
        lirName: [
          {required: true, message: "请输入馆名称", trigger: "blur"},
        ],
        barCodeLen: [{required: true, message: "请输入条码长度", trigger: "blur"}],
        beginBarCode: [{required: true, message: "请填写开始条码", trigger: "blur"}],
        barCodeNum: [
          {required: true, message: "请输入条码个数", trigger: "blur"},
        ],
      },
      formBarCode: {},
      printListBarCode: {
        lirName: '',
        barCode: ''
      },
      // 垂直
      printSetBarCode: {
        // 纸张
        width: 210,
        // 条码
        barCodeHeight: 23,
        columns: 5,
        // 书标区域
        paddingLeft: 10,
        paddingTop: 5,
        paddingRight: 10,
        paddingBottom: 0,
        //间距
        marginLeft: 4,
        marginTop: 3,
        // 内容排列
        // 垂直
        verticalAlign: "center",
        // 水平
        horizontalAlign: "center",
        // 行高
        lineHeight: 18,
        // 字体大小
        fontSize: 16,
      },
    };
  },

  computed: {

    uploadDisabled: function () {
      if (this.fileList.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    printListFilter() {
      let data = [];
      // if (this.preview) {
      //   data = this.printList.slice(0, this.printSet.col * this.printSet.row);
      // } else {
      data = this.printList;
      // }
      return data;
    },
    // // 占位循环次数
    // placeTotal() {
    //   let count = 0;
    //   const onePageCount = this.printSet.row * this.printSet.col;
    //   const total = this.printList.length;
    //   if (
    //     total > onePageCount &&
    //     total % onePageCount !== 0 &&
    //     total % onePageCount <= this.printSet.col
    //   ) {
    //     count = this.printSet.col - (total % onePageCount) + 1;
    //   }
    //   return count;
    // },
    // // 打印占位
    // place() {
    //   let bool = false;
    //   const onePageCount = this.printSet.row * this.printSet.col;
    //   const total = this.printList.length;
    //   if (
    //     total > onePageCount &&
    //     total % onePageCount !== 0 &&
    //     total % onePageCount <= this.printSet.col
    //   ) {
    //     bool = true;
    //   }
    //   return bool;
    // },

    //
    printListFilterBarCode() {

      let data = [];
      let lenzero = "000000000000000000000"
      let barcodeNum = this.formBarCode.beginBarCode
      let currentlen
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.printListSizeBarCode = this.formBarCode.barCodeNum
      for (let i = 0; i < this.formBarCode.barCodeNum; i++) {
        data[i] = {lirName: '', barCode: '', img: ''}
        data[i].lirName = this.formBarCode.lirName
        currentlen = (barcodeNum).toString().length
        console.log(currentlen)
        data[i].barCode = lenzero.substring(0, this.formBarCode.barCodeLen - currentlen) + barcodeNum.toString()
        data[i].img = "http://www.free-barcode.com/barcodemaker.asp?bc1=" + data[i].barCode + "&bc2=8&bc3=1.7&bc4=0.5&bc5=0&bc6=1&bc7=Arial&bc8=15&bc9=1"
        barcodeNum++
      }
      return data;
    }
    // printListFilterBarCode() {
    //   let data = [];
    //   let lenzero = "000000000000000000000";
    //   let barcodeNum = this.formBarCode.beginBarCode; // 强制转换为字符串
    //   let currentlen;
    //
    //   this.printListSizeBarCode = this.formBarCode.barCodeNum;
    //
    //   for (let i = 0; i < this.formBarCode.barCodeNum; i++) {
    //     data[i] = { lirName: '', barCode: '', img: '' };
    //     data[i].lirName = this.formBarCode.lirName;
    //     currentlen = barcodeNum.length; // 获取当前barcodeNum的长度
    //     console.log(currentlen);
    //
    //     data[i].barCode = lenzero.substring(0, this.formBarCode.barCodeLen - currentlen) + barcodeNum.toString();
    //     data[i].img = "http://www.free-barcode.com/barcodemaker.asp?bc1=" + data[i].barCode + "&bc2=10&bc3=3&bc4=0.6&bc5=0&bc6=1&bc7=Arial&bc8=15&bc9=1";
    //
    //     barcodeNum++; // 递增barcodeNum的值，确保下一个循环使用正确的递增值
    //   }
    //
    //   return data;
    // }

  },

  unmounted() {
    localStorage.removeItem("StrLotNumber")
    localStorage.removeItem("StrBookshelfID")
  },
  mounted() {

    this.uploadUrl = global.syOssUrl + '/upload2Oss'
    this.getSWList();
    if (localStorage.getItem("StrLotNumber")) {
      this.listQuery.Search = {
        StrLotNumber: localStorage.getItem("StrLotNumber"),
      }
    }
    //不会出现又查批号又查书架的情况
    if (localStorage.getItem("StrBookshelfID")) {
      this.listQuery.Search = {
        StrBookshelfID: localStorage.getItem("StrBookshelfID"),
      }
    }
    this.getList();

    this.getBatchNumber();
    if (localStorage.getItem("printSet") != null) {
      this.printSet = JSON.parse(localStorage.getItem("printSet"));
    }
  },
  methods: {

    // 设置
    styleSet() {
    },
      disabledDate (time)  {
          return time.getTime() > Date.now()
      },
      choose(){
        const day1 = new Date(this.search.DTStartCreatedDate)
        const day2 = new Date(this.search.DTEndCreatedDate)
        if (day2.getTime() < day1.getTime()){
            ElMessage.error('日期选择错误')
            this.search.DTEndCreatedDate = ''
        }
      },
    checkSpecificKey(str) {
      var specialKey =
          "[`~!#$^&*()=|{}':;',\\[\\]<>/?~！#￥……&*（）——|{}【】‘；：”“'。，、？]‘'";
      for (var i = 0; i < str.length; i++) {
        if (specialKey.indexOf(str.substr(i, 1)) != -1) {
          return true;
        }
      }
      return false;
    },
    check() {
      if (this.checkSpecificKey(this.formModel.StrTypeName)) {
        this.$message({
          message: "分类号不能包含.以外的其他符号,请检查分类号",
          type: "error",
        });
      }
    },
    //获取书屋列表
    getSWList() {
      this.$axios({
        url: this.localpath + "librarys",
        method: "get",
        //发送格式为json
        headers: {
          "Content-Type": "application/json",
          dataType: "json",
          Authorization: localStorage.getItem("token"),
        },
      }).then((res) => {
        if (res.data.ok) {
          this.swList = res.data.msg;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
    // 查询当前批号下的图书
    bookDataList(e) {
      var listQuery = {
        Page: 1,
        Perpage: 10,
        intNotOn: "1",
        Sort: "-1",
        OrderBy: "DTCreatedDate",
        Search: {
          StrLotNumber: e,
        },
      };
      this.$axios({
        url: this.localpath + "books/search",
        method: "post",
        //发送格式为json
        data: JSON.stringify(listQuery),
        headers: {
          "Content-Type": "application/json",
          dataType: "json",
          Authorization: localStorage.getItem("token"),
        },
      }).then((res) => {
        this.total = res.data.total;
      });
      this.formLableCode.Start = null;
      this.formLableCode.End = null;
    },
    // 取消
    closeLableCode() {
      this.dialogVisible = false;
      this.dialogBarCodeVisible = false;
    },
    beforeUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg.toLowerCase() === 'jpg'
      const extension2 = testmsg.toLowerCase() === 'jepg'
      const extension3 = testmsg.toLowerCase() === 'png'
      // const isLt2M = file.size / 1024 / 1024 < 10
      if (!extension && !extension2 && !extension3) {
        this.$message({
          message: '上传图片只能是jpg/jepg/png格式!',
          type: 'warning'
        });
        return false
      }
      return new Promise((resolve, reject) => {
        this.now = Date.parse(new Date()) / 1000;
        this.picheaders = {
          'timestamp': this.now,
          'sign': md5('6486b66b41f8df68777eac23' + this.now)
        },
            new Compressor(file, {
              quality: 0.6, // 设置压缩质量，范围为 0 到 1，默认为 0.8
              success(result) {
                resolve(result);
              },
              error(err) {
                reject(err);
              },
            });
      });
    },
    //预览图片
    handlePictureCardPreview(file) {
      this.showPictureCardPreview = true;
      this.showImg = file.url;
    },
    handleChange(file, fileList) {
      this.fileList = fileList
    },
    // 上传图片成功
    handleSuccess(response, file, fileList) {
      if (response.code === 0) {
        this.fileList = fileList
        this.formModel.StrCoverURL = response.msg
      }
    },
    // 上传图片移除
    handleRemove(file, fileList) {
      this.fileList = fileList;
      this.formModel.StrCoverURL = ''
    },
    //数据导出
    Download() {
      this.downloadLoading = true
      this.$axios({
        url: this.localpath + "books/export",
        method: "post",
        //发送格式为json
        data: JSON.stringify(this.listQuery),
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
          dataType: "json",
          Authorization: localStorage.getItem("token"),
        },
      }).then((res) => {
        const blob = new Blob([res.data], {type: 'application/vnd.ms-excel'}); //处理文档流
        var data = new Date()
        let timem = data.getTime();
        const fileName = "图书档案" + timem + ".xlsx";
        const elink = document.createElement("a");
        elink.download = fileName;
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
      }).finally(() => {
        this.downloadLoading = false
      })
    },
    // 确定
    labelCodeSure(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.formLableCode.type == 1) {
            this.formLableCode.OrderBy = "DTCreatedDate";
            this.formLableCode.Sort = "1";
            this.formLableCode.Start = parseInt(this.formLableCode.Start);
            this.formLableCode.End = parseInt(this.formLableCode.End);
          } else if (this.formLableCode.type == 2) {
            this.formLableCode.Start = null;
            this.formLableCode.End = null;
            this.formLableCode.OrderBy = "StrBookBarcode";
            this.formLableCode.Sort = "1";
          }
          this.$axios({
            url: this.localpath + "books/printsearch",
            method: "post",
            //发送格式为json
            data: JSON.stringify(this.formLableCode),
            headers: {
              "Content-Type": "application/json",
              dataType: "json",
              Authorization: localStorage.getItem("token"),
            },
          }).then((res) => {
            if (res.data.ok) {
              this.printList = res.data.msg;
              this.printListSize = this.printList.length
              this.dialogVisiblePrint = true;
            } else {
              this.$message.error(res.data.msg)
            }
          });
        } else {
          if (this.formLableCode.type) {
            if (!this.formLableCode.StrLotNumber) {
              return false;
            }
            if (!this.batchTotal) {
              this.$message({
                type: "warning",
                message: "当前暂无数据可选",
              });
            }
          }
        }
      });
    },
    // 批号编目
    getBatchNumber() {
      this.$axios({
        url: this.localpath + "lotnumbers",
        method: "get",
        //发送格式为json
        headers: {
          "Content-Type": "application/json",
          dataType: "json",
          Authorization: localStorage.getItem("token"),
        },
      }).then((res) => {
        this.batchList = res.data.msg;
      });
    },
    // 书标条码打印
    btnPrint() {
      this.formLableCode.Start = '';
      this.formLableCode.StrLotNumber = '';
      this.formLableCode.StrEndBookBarcode = '';
      this.formLableCode.StrStartBookBarcode = '';
      this.dialogVisible = true;
    },
    //获取所有的图书
    getALLList() {
      this.listQuery = {
        Page: 1,
        Perpage: 10,
        intNotOn: "1",
        Sort: "-1",
        OrderBy: "DTCreatedDate",
      };
      this.getList()
    },
    // 获取图书列表
    getList() {
      this.dialogFormVisible = false;
      this.listLoading = true;

      // this.getlist(this.formModel).then(res => {
      this.$axios({
        url: this.localpath + "books/search",
        method: "post",
        //发送格式为json
        data: JSON.stringify(this.listQuery),
        headers: {
          "Content-Type": "application/json",
          dataType: "json",
          Authorization: localStorage.getItem("token"),
        },
      }).then((res) => {
        if (res.data.ok) {
          this.list = res.data.msg;
          this.total = res.data.total;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
        this.listLoading = false;
      });
    },
    // 关闭dialog
    SWBGClose() {
      this.cancelBGSW();
    },
    // 取消dialog按钮
    cancelBGSW() {
      this.dialogBGSWVisible = false;
      this.formBGSWModel = {};
    },

    // 变更书屋
    btnUpdatePosition(row, boolean) {
      if (!this.dialogSJVisible) {
        this.dialogSJVisible = true;
        if (boolean) {
          this.formSJModel.StrBookID = row.StrID;
          // this.formSJModel.StrLibraryID=row.StrLibraryID
          // this.formSJModel.StrBookshelfID=row.StrBookshelfID
        } else {
          const strAarr = [];
          this.selectRows.forEach((item) => {
            strAarr.push(item.StrID);
          });
          this.formSJModel.StrBookID = strAarr.join(",");
        }
        this.$nextTick(() => {
          this.$refs["dataForm"].clearValidate();
        });
      } else {
        this.formSJModel.OnSelf = true;
        this.$refs["dataForm"].validate((valid) => {
          if (valid) {
            this.$axios({
              url: this.localpath + "books/onshelf",
              method: "post",
              data: JSON.stringify(this.formSJModel),
              //发送格式为json
              headers: {
                "Content-Type": "application/json",
                dataType: "json",
                Authorization: localStorage.getItem("token"),
              },
            }).then((res) => {
              if (res.data.ok) {
                this.$message({
                  message: "变更图书成功",
                  type: "success",
                });
                this.getList();
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "error",
                });
              }
            });
            this.formSJModel = {};
            this.dialogSJVisible = false;
          }
        });
      }
    },
    //删除图书
    BookDelete(row) {
      this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$axios({
          url: this.localpath + "books/" + row.StrID,
          method: "delete",
          //发送格式为json
          headers: {
            "Content-Type": "application/json",
            dataType: "json",
            Authorization: localStorage.getItem("token"),
          },
        }).then((res) => {
          if (res.data.ok) {
            this.$notify({
              message: "删除成功",
              type: "success",
              duration: 2000,
            });
          } else {
            this.$notify({
              message: res.data.msg,
              type: "error",
              duration: 5000,
            });
          }
          this.getList();
        });
      });
    },
    // 根据书屋名称查id
    swChange(val) {
      this.formSJModel.StrBookshelfID = null;
      this.swList.forEach((item) => {
        if (val === item.StrName) {
          this.formSJModel.StrLibraryID = item.StrID;
        }
      });
      this.getBookShelfList(this.formSJModel.StrLibraryID);
    },
    // 根据书屋获取书架
    getBookShelfList(val) {
      const param = {
        StrLibraryID: val,
      };
      this.$axios({
        url: this.localpath + "bookshelfs",
        method: "post",
        data: JSON.stringify(param),
        //发送格式为json
        headers: {
          "Content-Type": "application/json",
          dataType: "json",
          Authorization: localStorage.getItem("token"),
        },
      }).then((res) => {
        if (res.data.ok) {
          this.bookshelfs = res.data.msg;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
    // 添加
    handleCreate() {
      this.dialogStatus = "create";
      this.dialogFormVisible = true;
    },
    // 编辑
    async handleUpdate(row) {
      this.formModel.StrLibraryID = "";
      this.formModel.StrBookshelfID = "";
      this.fileList = []
      console.log('刚进来图书信息....', this.fileList)
      this.dialogStatus = "update";
      this.dialogFormVisible = true;
      // const param = { strId: row.strId }
      this.getBookShelfList(row.StrLibraryID);
      const res = await this.$axios({
        url: this.localpath + "books/" + row.StrID,
        method: "get",
        //发送格式为json
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      if (res.data.ok) {
        //对象
        this.formModel = res.data.msg;
        const coverUrl = res.data.msg.StrCoverURL;
        if (coverUrl !== "") {
          // this.fileList.push({
          //   url: res.data.msg.StrCoverURL,
          // });
          this.checkImage(coverUrl)
              .then((result) => {
                if (result) {
                  // 图片路径可访问
                  this.fileList.push({
                    url: coverUrl,
                  });
                } else {
                  // 图片路径无法访问，添加默认图片URL
                  this.fileList.push({
                    url: require('../../assets/img/defaultBook.png'),
                  });
                }
              })
              .catch(() => {
                // 图片路径检查出错，添加默认图片URL
                this.fileList.push({
                  url: require('../../assets/img/defaultBook.png'),
                });
              });
        } else {
          const imgsrc = 'https://sanyue-oss-public.oss-cn-hangzhou.aliyuncs.com/image/bookcover/' + res.data.msg.StrISBN + '.jpg'
          // this.fileList.push({
          //   url: 'https://sanyue-oss-public.oss-cn-hangzhou.aliyuncs.com/image/bookcover/' + res.data.msg.StrISBN + '.jpg',
          // });
          this.checkImage(imgsrc)
              .then((result) => {
                if (result) {
                  // 图片路径可访问
                  this.fileList.push({
                    url: imgsrc,
                  });
                } else {
                  // 图片路径无法访问，添加默认图片URL
                  this.fileList.push({
                    url: require('../../assets/img/defaultBook.png'),
                  });
                }
              })
              .catch(() => {
                // 图片路径检查出错，添加默认图片URL
                this.fileList.push({
                  url: require('../../assets/img/defaultBook.png'),
                });
              });
        }
        console.log('更换的图书信息....', this.fileList)
        //如果书借出了就去查询谁借阅了
        if (this.formModel.IsBorrowed) {
          this.$axios({
            url:
                this.localpath +
                "books/" +
                this.formModel.StrBookBarcode +
                "/reader",
            method: "get",
            //发送格式为json
            headers: {
              "Content-Type": "application/json",
              dataType: "json",
              Authorization: localStorage.getItem("token"),
            },
          }).then((res) => {
            this.formModel.currentReaerName = res.data.msg.StrName;
            this.formModel.currentReaerNumber =
                res.data.msg.StrReaderCardNumber;
          });
        }

        if (this.formModel.IsBorrowed) {
          this.formModel.IsBorrowed = "出借";
        } else {
          this.formModel.IsBorrowed = "在库";
        }

        if (this.formModel.IsBroken) {
          this.formModel.IsBroken = "已损毁";
        } else {
          this.formModel.IsBroken = "未损毁";
        }

        if (this.formModel.IsLost) {
          this.formModel.IsLost = "已丢失";
        } else {
          this.formModel.IsLost = "未丢失";
        }
        let swBool = false;
        let sjBool = false;
        // 书屋判断
        if (res.data.msg.strCollectionLocationId) {
          this.swList.forEach((item) => {
            if (item.StrID === res.data.msg.StrLibraryID) {
              swBool = true;
            }
          });
          if (!swBool) {
            this.formModel.StrLibraryID = "";
          } else {
            this.formModel.StrLibraryID = res.data.msg.StrLibraryID;
          }
        } else {
          this.formModel.StrLibraryID = res.data.msg.StrLibraryID;
        }
        // 书架判断
        if (this.sjList) {
          this.sjList.forEach((item) => {
            if (res.data.msg.StrBookshelfID === item.StrID) {
              sjBool = true;
            }
          });
        }
        if (!sjBool) {
          this.formSJModel.StrBookshelfID = "";
        }
      }
    },
    checkImage(url) {
      return new Promise((resolve) => {
        const img = new Image();
        img.onload = function() {
          resolve(true); // 图片加载成功
        };
        img.onerror = function() {
          resolve(false); // 图片加载失败
        };
        img.src = url;
      });
    },
    // 查询
    handleFilter() {
      this.listQuery.Page = 1
      this.listQuery.Perpage = 10
      this.listLoading = true;

      //不存在，则创建，否则，不要去打扰Search中的其他条件，比如说 批号
      if(this.listQuery.Search === undefined){
        this.listQuery.Search = {}
      }
      //判断一个既可以了
      if (this.search.StrISBN !== undefined)
        this.listQuery.Search["StrISBN"] = this.search.StrISBN;
      if (this.search.StrFirstResponsible !== undefined)
        this.listQuery.Search["StrFirstResponsible"] = this.search.StrFirstResponsible;
      if (this.search.StrPositiveTitle !== undefined)
        this.listQuery.Search["StrPositiveTitle"] = this.search.StrPositiveTitle;
      if (this.search.DTStartCreatedDate !== undefined)
        this.listQuery.Search["DTStartCreatedDate"] = this.search.DTStartCreatedDate + 'T00:00:00.999Z'
      if (this.search.DTEndCreatedDate !== undefined)
        this.listQuery.Search["DTEndCreatedDate"] = this.search.DTEndCreatedDate +'T23:59:59.999Z'
      if (this.search.StrTypeName !== undefined)
        this.listQuery.Search["StrTypeName"] = this.search.StrTypeName;
      if (this.search.StrBookBarcode !== undefined)
        this.listQuery.Search["StrBookBarcode"] = this.search.StrBookBarcode;
      if (this.search.StrLibraryID !== undefined)
        this.listQuery.Search["StrLibraryID"] = this.search.StrLibraryID;

      this.listQuery.OrderBy = "DTCreatedDate";
      this.getList();
      this.dialogTSCXVisible = false;
    },
    // 图书修改确定
    updateData() {
      this.$refs.dataTsForm.validate(async (valid) => {
        if (valid) {
          //通过书屋信息查询书屋设置的条形码长度
          var strLibraryLength = await this.getBookcode(
              this.formModel.StrLibraryID
          );
          if (this.formModel.StrBookBarcode.length != strLibraryLength) {
            this.$message({
              type: "error",
              message: "条形码长度必须为" + strLibraryLength + "位",
            });
            return false;
          }
          this.formModel.FloatPricePer =
              this.formModel.FloatPricePer.toString();
          this.formModel.IntReservationRoyalty = parseInt(
              this.formModel.IntReservationRoyalty
          );
          this.formModel.IntMemoryCopyNum = parseInt(
              this.formModel.IntMemoryCopyNum
          );
          this.formModel.IntNumberCopies = parseInt(
              this.formModel.IntNumberCopies
          );
          if (this.formModel.IsBorrowed == "出借") {
            this.formModel.IsBorrowed = true;
          } else {
            this.formModel.IsBorrowed = false;
          }

          if (this.formModel.IsBroken == "已损毁") {
            this.formModel.IsBroken = true;
          } else {
            this.formModel.IsBroken = false;
          }

          if (this.formModel.IsLost == "已丢失") {
            this.formModel.IsLost = true;
          } else {
            this.formModel.IsLost = false;
          }

          this.$axios({
            url: this.localpath + "books/" + this.formModel.StrID,
            method: "patch",
            data: JSON.stringify(this.formModel),
            //发送格式为json
            headers: {
              "Content-Type": "application/json",
              dataType: "json",
              Authorization: localStorage.getItem("token"),
            },
          })
              .then((res) => {
                if (res.data.ok) {
                  this.$message({
                    message: res.data.msg,
                    type: "success",
                  });
                  this.getList();
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "error",
                  });
                }
              })
              .catch();
          this.dialogFormVisible = false;
        } else {
          return false;
        }
      });
    },
    //根据书屋获取条形码长度
    async getBookcode(val) {
      const res = await this.$axios({
        url: this.localpath + "librarys/" + val,
        method: "get",
        //发送格式为json
        headers: {
          "Content-Type": "application/json",
          dataType: "json",
          Authorization: localStorage.getItem("token"),
        },
      });
      if (res.data.ok) {
        return res.data.msg.IntBookBarcodeLength;
      } else {
        this.$message({
          message: res.data.msg,
          type: "error",
        });
      }
    },
    // 跳转单元
    gotoBookcatalog() {
      this.$router.push("/bookcatalog");
    },
    pageSizeChange(Perpage) {
      this.listQuery.Perpage = Perpage;
      this.getList();
    },
    currPageChange(Page) {
      this.listQuery.Page = Page;
      this.getList();
    },
    // 还原打印设置
    resetPrint() {
      localStorage.removeItem("printSet");
      this.printSet = {
        width: 210,
        height: 297,
        paddingLeft: 10,
        paddingTop: 5,
        paddingRight: 10,
        paddingBottom: 0,
        col: 5,
        row: 10,
        verticalAlign: "center",
        horizontalAlign: "center",
        lineHeight: 18,
        fontSize: 14,
        overturn: '2'
      };
    },
    // 打印
    printTrue() {
      localStorage.setItem("printSet", JSON.stringify(this.printSet));
      this.preview = false;
      this.$nextTick(() => {
        this.$print(this.$refs.print);
      });
    },


    //***************按钮条码打印的方法区域**************
    // 打印
    printTrueBarCode() {
      this.previewBarCode = false;
      this.$nextTick(() => {
        this.$print(this.$refs.printBarCode);
      });
    },
    //还原条码打印设置
    resetPrintBarCode() {
      this.printSetBarCode = {
        width: 210,
        barCodeHeight: 23,
        columns: 5,
        // 书标区域
        paddingLeft: 10,
        paddingTop: 5,
        paddingRight: 10,
        paddingBottom: 0,
        //间距
        marginLeft: 4,
        marginTop: 3,
        // 内容排列
        // 垂直
        verticalAlign: "center",
        // 水平
        horizontalAlign: "center",
        // 行高
        lineHeight: 18,
        // 字体大小
        fontSize: 16,
      }
    },
    // 条码打印
    btnBarCodePrint() {
      this.formBarCode.lirName = '';
      this.formBarCode.barCodeLen = '';
      this.formBarCode.beginBarCode = '';
      this.formBarCode.barCodeNum = '';
      this.dialogBarCodeVisible = true
    },
    barCodeSure() {
      this.$refs['formBarCode'].validate((valid) => {
        if (valid) {

          this.dialogBarCodeVisible = false;
          this.dialogVisiblePrintCodeBar = true;

        } else {
          //???
          if (this.formLableCode.type) {
            if (!this.formLableCode.StrLotNumber) {
              return false;
            }
            if (!this.batchTotal) {
              this.$message({
                type: "warning",
                message: "当前暂无数据可选",
              });
            }
          }
        }
      });
    },
  },
};
</script>
<style scoped>
.disabled /deep/ .el-upload--picture-card {
  display: none;
}

.el-form--inline .el-form-item {
  margin-right: 0;
}

/*ul,li {*/
/*  list-style-type: none;*/
/*  padding: 0;*/
/*  margin: 0;*/
/*}*/
.print-set .print-set-item {
  padding: 5px;
  display: flex;
  align-items: center;
}

.print-set .print-set-item:nth-of-type(odd) {
  background-color: #fafbfc;
}

.print-set .print-set-item:nth-of-type(even) {
  background-color: #eef1f3;
}

.print-set .print-set-item .print-set-title {
  width: 80px;
  font-size: 16px;
  color: #555;
  font-weight: bold;
}

.print-set .print-set-item .print-set-option {
  width: 60px;
  padding: 0 6px;
  text-align: right;
}

.print-box {
  position: absolute;
  top: 0;
  left: 100%;
  background-color: #fff;
  transform-origin: 0 0;
  border-left: 1px solid #000;
}

.filter-container {
  /*border-top: 10px solid white;*/
  border-bottom: 10px solid white;
}
</style>

